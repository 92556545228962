import React from "react"

export default () => {
  return (
    <div className="resume">
      <section className="header">
        <h1 className="name">Kay Hoogland</h1>
        <p className="title">Machine Learning Engineer</p>
        <div className="contact_info">
          <p>+316 1269 2428</p>
          <p>'s-Hertogenbosch, The Netherlands</p>
          <a href="https://www.linkedin.com/in/kay-hoogland/">
            linkedin.com/in/kay-hoogland
          </a>
          <a href="https://github.com/kayhoogland">github.com/kayhoogland</a>
          <a href="mailto:kay@kayhoogland.nl">kay@kayhoogland.nl</a>
        </div>
      </section>
    </div>
  )
}
